<template>
    <div class='sign-up-header'>
            <img class='nav-image' src='../../public/Invrs-Logo-Green-Blue.png' style="cursor: pointer;" @click="goHome()">
            <div class="nav-items">
                <a class="login-link" @click="goToLoginPage">Login</a>
                <button class="signup-cta" @click="goToSignUpPage">Sign Up</button>
            </div>
    </div>

    <div class='group-preview-content'>
        <div class='sign-in-container' v-if="display">
            <ProfileCard class='group-profile' :headerText="group.name" :subText="groupSubInfo(group.memberCount, group.groupTypeId, group.isPrivate)" :bioText="group.bio"
            :avatar="group.avatar ? group.avatar : generateGroupAvatar(group.name)" />
            <template v-if="group.groupLandingPage">
                <div class="card" > 
                    <ScrollPanel class="landing-container">
                    <div class="landing-preview" v-html="group.groupLandingPage.content" ></div>
                    <div v-if="group.groupLandingPage.includeCourses"  style="margin-top:15px;">
                    
                        <GroupLandingPageCourseList @join-course="onClick" :courses="group.openCourses" style="margin-bottom:10rem;display:flex;flex-direction:column;"/>
                    </div>
                    </ScrollPanel>
                </div>
            
            </template>
            <template v-else v-for="activity in activities" :key="activity.id">
               <GroupPreviewActivityCard :activity="activity" @on-view-details="onClick" @on-account-needed="onClick"></GroupPreviewActivityCard>
            </template>

            <button class="bottom-signup-cta" @click="goToSignUpPage">Sign up to see more &#8594;</button>
        </div>
    </div>

    <AccountNeededModal ref='accountNeededModal' @to-login="goToLoginPage" @to-signup="goToSignUpPage"/>

</template>

<script>
import ProfileCard from '../components/profile/ProfileCard.vue';
import AccountNeededModal from '../components/modal/AccountNeededModal.vue';
//import UserAvatar from '../components/profile/UserAvatar.vue';


import { HOME,APP_URL, LOGIN_PAGE, SIGNUP_PAGE} from '@/common/config';
import AppService from '../service/AppService';//eslint-disable-line
import GroupService from '../service/GroupService';
import FeedService from '../service/FeedService';
import BrowserUtils from '../utilities/BrowserUtils';
import StringUtils from '../utilities/StringUtils';
import TimeUtils from '../utilities/TimeUtils';
import PostUtils from '../utilities/PostUtils';
import { groupSubInfo, generateGroupAvatar } from '../components/groups/';

import clip from 'text-clipper';

import GroupPreviewActivityCard from './GroupPreviewActivityCard';

import {PASSED_MOTION_STATUS, FAILED_MOTION_STATUS, WITHDRAWN_MOTION_STATUS, VETOED_MOTION_STATUS,YESNO_POLL, MULTIPLE_CHOICE_POLL, MULTIPLE_SELECT_POLL} from '@/common/constants';

import ScrollPanel from 'primevue/scrollpanel';

import GroupLandingPageCourseList from '../components/groups/GroupLandingPageCourseList.vue';

export default {
    name: 'GroupPreview',

    components: {
        ProfileCard, AccountNeededModal,
        GroupPreviewActivityCard,GroupLandingPageCourseList,ScrollPanel
    },
    data () {
        return {
            WITHDRAWN_MOTION_STATUS, 
            VETOED_MOTION_STATUS,
            PASSED_MOTION_STATUS,
            FAILED_MOTION_STATUS,
            YESNO_POLL, MULTIPLE_CHOICE_POLL, MULTIPLE_SELECT_POLL,
            userName: null,
            password: null,
            loading: false,
            inviteCode: null,
            
            group: null,
            activities: [],
            display: false,

            stringUtils: StringUtils,
            timeUtils: TimeUtils,
             postUtils: PostUtils,
        }
    },
    computed: {
        customAvatarStyle() {
            return this.isMobile() ? 'flex: 1 0 auto' : '';
        },
         activityContentDivId(){
            let prefix = ''
           return prefix+`activityContent-${this.activity.id}`;
            
        },
         pollClosed() {
            if( this.activity.poll?.closedDate ){
                return true;
            }
            else {
                //return TimeUtils.isSameOrBeforeNow(this.activity.poll?.closedDate);
                return false;
            }
        },
          originalPosterFullName() {
            const firstName = (this.activity.object.actor.data.firstName) ? this.activity.object.actor.data.firstName : '';
            const middleName = (this.activity.object.actor.data.middleName) ? this.activity.object.actor.data.middleName : '';
            const lastName = (this.activity.object.actor.data.lastName) ? this.activity.object.actor.data.lastName : '';

            return `${firstName} ${middleName} ${lastName}`
        },

         fullName() {
            // console.log("fullName", this.activity);
            const firstName = (this.activity.actor.data.firstName) ? this.activity.actor.data.firstName : '';
            const middleName = (this.activity.actor.data.middleName) ? this.activity.actor.data.middleName : '';
            const lastName = (this.activity.actor.data.lastName) ? this.activity.actor.data.lastName : '';

            return `${firstName} ${middleName} ${lastName}`
        },

        deadlineHasPassed() {
            return TimeUtils.isSameOrBeforeNow(this.activity.motion.deadlineDate);
        },

        deadlineDate() {
            return TimeUtils.getFormattedTime(this.activity.motion.deadlineDate, 'YYYY-MM-DD');
        },

         motionStatusText() {
            const MOTION_STATUS_MAP = {
                [PASSED_MOTION_STATUS]: "<span class='passed'>Motion Passed</span> <span class='material-symbols-outlined'>check_circle</span>",
                [WITHDRAWN_MOTION_STATUS]: "<span class='other'>Motion Withdrawn</span>",
                [FAILED_MOTION_STATUS]: "<span class='other'>Motion Failed</span> <span class='material-symbols-outlined'>cancel</span>",
                [VETOED_MOTION_STATUS]: "<span class='other'>Motion Vetoed</span>"
            }

            return MOTION_STATUS_MAP[this.activity.motion.motionStatus];
        },
    },

    mounted() {
        document.getElementById('app').style.height = '100%';
        
        this.inviteCode = this.$route.query.invite;
        if( this.inviteCode ){
            GroupService.getGroupInvitePreview(this.inviteCode).then( resp => {
                
                if( resp.data.status == 'success'){
                    this.display = true;   
                    this.group = resp.data.group;
                    this.activities = FeedService.deNormalizeActivities(resp.data.activities);
                    //this.activities = resp.data.activities;
                   
                }
                else {
                    console.log("error getting group invite preview "+ resp.data.message);
                }
            }).catch(error => {
                 console.log("error getting group invite preview "+ error);
            })
        }
        


        
       
    },

    

    methods: {
        groupSubInfo,
        generateGroupAvatar,
       
        goHome() {
            window.location.href = HOME;
        },
        goToLoginPage() {
            window.location.href = APP_URL+LOGIN_PAGE + '?invite=' + this.inviteCode;
        },
        goToSignUpPage() {
            window.location.href = APP_URL+SIGNUP_PAGE + '?invite=' + this.inviteCode;
        },

        // All Activity Card methods

        viewMoreCommentsText(activity) {
            const text = (activity.num_comments) == 2 ? 'comment' : 'comments';

            return `View ${activity.num_comments - 1} more ${text}`;
        },

         isMobile() {
            return BrowserUtils.isMobile();
        },

        

        getPostPreviewContent(activity) {
            return this.pruneContent(activity);

        }, 


        pruneContent(activity) {
            let content = activity.extra.content ? activity.extra.content : activity.extra.summary;

            if( content ) {
                let virtualContentNode = new DOMParser().parseFromString(content, 'text/html');
                this.pruneContentNode(virtualContentNode.body, activity);
                return virtualContentNode.body.innerHTML;
            }
            else {
                return content;
            }
           
        },

        /* Does the removing of images from preview, the clamping of text if too long for previews, and
        preventing the dialog to popup when clicking on normal links */
        pruneContentNode(postContentNode, activity) {
            if (postContentNode) {               
                this.removeMostMediaFromPreview(postContentNode);
                this.clampActivityPreview(postContentNode, activity);
                this.openModalAfterClickingLinksAndImages(postContentNode);

            }
        },

        // Removes all images and removes all embeds except the first one to allow for video previews on feed.
        removeMostMediaFromPreview(postContent) {
            Array.from(postContent.getElementsByTagName('IMG')).forEach((img) => {
                img.remove();
            })
            
            Array.from(postContent.getElementsByTagName('FIGURE')).forEach((fig) => {
                if( fig.getAttribute("class") == 'image' ) {
                    
                    fig.remove();
                }
            })
            

            let embedCount = 0;
            Array.from(postContent.querySelectorAll('div[data-oembed-url]')).forEach((embed) => {
                if( embedCount > 0 ) { // leave one iframe for media previews
                    embed.remove();
                    
                }
                ++embedCount;
            })


           
        },

        openModalAfterClickingLinksAndImages(postContent) {
            Array.from(postContent.getElementsByTagName('IMG')).forEach((img) => {
                img.addEventListener('click', () => {
                    this.$refs.accountNeededModal.open();
                })
            })

            Array.from(postContent.getElementsByTagName('A')).forEach((link) => {
                link.addEventListener('click', () => {
                    this.$refs.accountNeededModal.open();
                })
            })
        },
            
        thumbnailClass(url) {
            let img = document.createElement("IMG");
            img.src = url;

            return {
                'image-preview-thumbnail': true,
                'wide-photo': img.width > img.height
            }
        },

        hasComments(activity) {
            return activity.latest_reactions && activity.latest_reactions.comment && activity.latest_reactions.comment.length > 0;
        },
        hasLikeCounts(activity) {
            return activity.reaction_counts && activity.reaction_counts.like;
        },

        hasCommentCounts(activity) {
            return activity.extra.num_comments;
        },

        getCommentCount(activity) {
            return this.hasCommentCounts(activity) ? '' +  activity.extra.num_comments : '';
        },

        onClick() {
            this.$refs.accountNeededModal.open();
        },

        textLength(activity) {
            const elementContent = this.getPostPreviewContent(activity);

            return elementContent.length;
        },

        findNearestParent(node) {
            let parent = node.parentNode;
            const tagNames = ['STRONG', 'A', 'I'];

            /* This is to ensure that the view more element is not within the tags from bolding, italizing and of a mention - Just prevents any
            accidental sharing of styling or wierd clicking behaviour (If it's in a mention) */
            while (tagNames.includes(parent.tagName)) {
                parent = parent.parentNode;
            }

            return parent;
        },
        clampActivityPreview(element, activity) {
            const textLength = element.innerText.length;
            
            if (textLength >= (this.isMobile() ? 200: 300)) {
                let parentNode = null;
                const id = `${activity.id}-viewMoreText`;
                const indicatorString = `<a id="${id}-viewMoreText" class='view-more-text' href='#'>...View more</a>`;

                // 268 includes the character length of the view more link element
                element.innerHTML = clip(element.innerHTML, 268, {breakWords: true, html: true, indicator: indicatorString})

                this.$nextTick(() => {
                    const viewMoreTextEl = document.getElementById(`${id}-viewMoreText`);

                    // For some reason, if the clipping happens to be at the actual end of a string - It won't add the indicator value (The view more element). Most likely due to HTML structure
                    if (!viewMoreTextEl) { // Manually add in the view more element at the end of element/string in the last child (Including nested) of the whole content
                        const newViewMoreTextEl = document.createElement('A');
                        newViewMoreTextEl.innerText = '...View More';
                        newViewMoreTextEl.id = `${id}-viewMoreText`;
                        newViewMoreTextEl.className = 'view-more-text';

                        let lastChild = element.lastChild;

                        while(lastChild) {
                            if (lastChild.lastChild) {
                                lastChild = lastChild.lastChild;
                            } else {
                                break;
                            }
                        }

                        parentNode = this.findNearestParent(lastChild);

                        // We find the most nearest parent that's not one of those types of elements, and append it as the last child (So that the link element is still beside it and inline)
                        parentNode.appendChild(newViewMoreTextEl);

                        // We replace the content within the activity content div with the new content that has the appended view more text
                        document.getElementById(`activityContent-${activity.id}`).firstChild.replaceWith(element.firstChild);
                    }
                });
            }
        }
        
       
      
        
    }
}
</script>

<style>
.landing-preview .ql-align-center {
    text-align: center;
}

.landing-preview .ql-align-right {
    text-align: right;
}

.landing-preview .ql-align-justify {
    text-align: justify;
}


.landing-preview ol li:before {
        content: counter(list-0,decimal) ". ";
    }
    .landing-preview ol li.ql-indent-1:before {
        content: counter(list-1,lower-alpha) ". ";
    }

    .landing-preview ol li.ql-indent-2:before {
        content: counter(list-2,lower-roman) ". ";
    }
    .landing-preview ol li.ql-indent-3:before {
        content: counter(list-3,decimal) ". ";
    }
    .landing-preview ol li.ql-indent-4:before {
        content: counter(list-4,lower-alpha) ". ";
    }
    .landing-preview ol li.ql-indent-5:before {
        content: counter(list-5,lower-roman) ". ";
    }
    .landing-preview ol li.ql-indent-6:before {
        content: counter(list-6,decimal) ". ";
    }
    .landing-preview ol li.ql-indent-7:before {
        content: counter(list-7,lower-alpha) ". ";
    }
    .landing-preview ol li.ql-indent-8:before {
        content: counter(list-8,lower-roman) ". ";
    }

 .landing-preview li:before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
}
.landing-preview li:not(.ql-direction-rtl):before {
    margin-left: -1.5em;
    margin-right: .3em;
    text-align: right;
}

.landing-preview ul>li:before {
    content: "\2022";
}

.landing-preview li:before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
}

  .landing-preview ol>li, .landing-preview ul>li {
    list-style-type: none;
} 

.landing-preview ol, .landing-preview ul{
    
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    list-style-position: inside;
}

 .landing-preview ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0
}

.landing-preview ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-1;
} 

/* .landing-preview ol li.ql-indent-1 {
    counter-increment: list-1;
} */

.landing-preview ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-2 {
    counter-increment: list-2;
}

.landing-preview ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-3 {
    counter-increment: list-3;
}

.landing-preview ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-4 {
    counter-increment: list-4;
}

.landing-preview ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-5 {
    counter-increment: list-5;
}

.landing-preview ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-6 {
    counter-increment: list-6;
}

.landing-preview ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
}

.landing-preview ol li.ql-indent-7 {
    counter-increment: list-7;
}

.landing-preview ol li.ql-indent-8 {
    counter-reset: list-9;
}

.landing-preview ol li.ql-indent-8 {
    counter-increment: list-8;
}
</style>

<style scoped>
*:not(.pi) {
    font-family: "Trebuchet MS", "Verdana";
}


::v-deep(.landing-preview ol li:not(.ql-direction-rtl), .landing-preview ul li:not(.ql-direction-rtl) ){
    padding-left: 1.5em;
}

::v-deep(.landing-preview li.ql-indent-1:not(.ql-direction-rtl) ){
    padding-left: 4.5em;
}
::v-deep(.landing-preview li.ql-indent-2:not(.ql-direction-rtl) ){
    padding-left: 7.5em;
}
::v-deep(.landing-preview li.ql-indent-3:not(.ql-direction-rtl) ){
    padding-left: 10.5em;
}
::v-deep(.landing-preview li.ql-indent-4:not(.ql-direction-rtl) ){
    padding-left: 13.5em;
}
::v-deep(.landing-preview li.ql-indent-5:not(.ql-direction-rtl) ){
    padding-left: 16.5em;
}
::v-deep(.landing-preview li.ql-indent-6:not(.ql-direction-rtl) ){
    padding-left: 19.5em;
}
::v-deep(.landing-preview li.ql-indent-7:not(.ql-direction-rtl) ){
    padding-left: 22.5em;
}
::v-deep(.landing-preview li.ql-indent-8:not(.ql-direction-rtl) ){
    padding-left: 25.5em;
}

::v-deep(.landing-preview .ql-indent-1:not(.ql-direction-rtl)){
    padding-left: 3em;
}
::v-deep(.landing-preview .ql-indent-2:not(.ql-direction-rtl)){
    padding-left: 6em;
}
::v-deep(.landing-preview .ql-indent-3:not(.ql-direction-rtl)){
    padding-left: 9em;
}
::v-deep(.landing-preview .ql-indent-4:not(.ql-direction-rtl)){
    padding-left: 12em;
}
::v-deep(.landing-preview .ql-indent-5:not(.ql-direction-rtl)){
    padding-left: 15em;
}
::v-deep(.landing-preview .ql-indent-6:not(.ql-direction-rtl)){
    padding-left: 18em;
}
::v-deep(.landing-preview .ql-indent-7:not(.ql-direction-rtl)){
    padding-left: 21em;
}
::v-deep(.landing-preview .ql-indent-8:not(.ql-direction-rtl)){
    padding-left: 24em;
}

.question-post {
    color: #32364E; /*#33CC99;*/
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 26px;
}

.poll {
    font-size:18px;
    left: 12px;
    top: -4px;
}

.motion-post {
    color: #32364E; /*#33CC99;*/
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 26px;
}

.poll-option{
    margin-bottom: 5px;
    font-size: 1.5rem;
}

.polling-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
}
.voting-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
}
.voting-container button, .polling-container button {
    display: flex;
    align-items: center;
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 0px 8px;
    background: none;
}
.voting-container button:hover, .polling-container button:hover{
    transform:rotate(0.2deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
    animation-iteration-count: infinite;
    background: none;
    border-color: none;
    box-shadow: none;
}
.voting-container .yes-vote-button, .polling-container .yes-vote-button {
    margin-right: 12px;
}
.voting-container .yes-vote-button:hover, .polling-container .yes-vote-button:hover {
    border-color: #33CC99;
}
.voting-container .no-vote-button:hover {
    border-color: #E63E3E;
}
.voting-container .vote-label, .polling-container .vote-label{
    font-weight: bold;
    color: #32364e;
    font-size: 14px;
}
.voting-container .yes-vote-button .vote-icon, .polling-container .yes-vote-button .vote-icon{
    color: #33CC99;
}
.voting-container .no-vote-button .vote-icon {
    color: #E63E3E;
}
.voting-container .vote-icon , .polling-container .vote-icon{
    font-size: 24px;
    
}

.looks-container{
    margin-left: auto;
    font-size: 12px;
}

.likes-container {
    font-size: 12px;
}
.tag-mention {
    font-size: 12px;
}

::v-deep(.activity-content ul) {
    padding-inline-start: 40px;
}
::v-deep(.activity-content ol) {
    padding-inline-start: 40px;
}

/* STYLING FOR MOTION ACTIVITY CARDS */
.motion-status-text {
    font-size: 11px;
    margin-top:10px;
}
.motion-status-text .passed {
    color: #33CC99;
    font-weight: bold;
}
.motion-status-text .failed {
    color: #E63E3E;
    font-weight: bold;
}
.motion-status-text .deadline-passed,
.motion-status-text .other {
    color: #32364E;
}
.motion-status-text .voted {
    color: #32364E;
}
.motion-status-text .material-symbols-outlined {
    color: #32364E;
    font-size: 16px;
}

.motion-status-text .voting-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.motion-status-text .voting-container .other {
    margin-right: 6px;
}
.motion-status-text .voting-container .vote-button {
    display: flex;
    align-items: center;
    border: 1px solid #32364E;
    border-radius: 50px;
    padding: 4px;
    background: none;
}
.motion-status-text .voting-container .vote-button:hover {
    transform:rotate(0.2deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
    animation-iteration-count: infinite;
    background: none;
}
.motion-status-text .voting-container .vote-button:focus {
    box-shadow: none;
}
.motion-status-text .voting-container .vote-button ::v-deep(.p-button-label) {
    font-weight: bold;
    color: #32364e;
    font-size: 12px;
}

.motion-activity-block .activity-stats-bar {
    align-items: center;
}
/* .motion-activity-block .looks-container {
    margin: 0;
} */
.motion-status-text {
    margin-left: auto;
    margin-right:auto;
}

.sign-up-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    position: fixed;
    background-color: #fff;
    padding: 0 30px;
    z-index: 1;
}
.p-button-text {
    font-weight: bold;
    color: #32364e;
}
.p-button-text:enabled:hover {
    background: none;
    color: #33CC99;
}
.p-button-text:enabled:focus {
    box-shadow: none;
    border: none;
}

.sign-up-button {
    margin-left: auto;
    font-size: 25px;
    padding: 9px 15px;
}

.group-preview-content {
    background: #F2F4FA;
    height: 100%;
}

.sign-in-container {
    display: flex;
    flex-direction: column;
    margin: 0px 32px;
    align-items: center;
}

.sign-in-title {
    font-size: 60px;
    color: #32364e;
    padding-bottom: 0.7em;
}

.button-container {
    display: flex;
}

.nav-image {
    height: 50px;
}

.nav-items {
    display: flex;
    align-items: center;
}

.login-link {
    text-decoration: none;
    margin-right: 20px;
    font-size: 16px;
    color: #333;
    font-weight: 600;
}

.login-link:hover {
    cursor: pointer;
}

.signup-cta, .bottom-signup-cta {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  background-image: linear-gradient(
    to right,
    #693bf5,
    rgb(105, 59, 245, 0.85),
    rgb(105, 59, 245, 0.7)
  );
  transition: all 0.1s ease-in;
}

.signup-cta:hover, .bottom-signup-cta:hover {
    box-shadow: 0 0.4rem 3.2rem rgb(105, 59, 245, 0.6);
    transform: scale(1.05);
    cursor: pointer;
}

.signup-cta:active, .bottom-signup-cta:active {
    transform: scale(0.95);
}

.bottom-signup-cta {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 80px;
}

::v-deep(.group-profile) {
    margin-top: 104px;
}

.activity-card {
    padding: 0px;
    border-radius: 16px;
    margin-bottom: 16px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 55%;
    background: #FFF;
}
.activity-card:hover {
    cursor: pointer;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
}
.activity-card:last-of-type {
    margin-bottom: 84px;
}

.activity-block {
    padding: 16px 16px 0px;
}

.status-post-owner-info {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.status-post-owner-info .names-container {
    display: block;
    padding-right: 15px;
}
.status-post-owner-info .names-container .full-name {
    display: block;
    color: #32364e;
    font-size: 18px;
    font-weight: bold;
}
.status-post-owner-info .names-container .username {
    display: block;
    color: #a6a6a6;
    font-size: 12px;
}

.status-post-owner-info .more-options {
    margin-left: auto;
    align-self: flex-start;
}

.repost-info {
    display: flex;
    align-items: center;
    padding: 16px 16px 0px;
}
.repost-info .username {
    color: #a6a6a6;
    font-size: 12px;
}
.repost-info .more-options {
    margin-left: auto;
    align-self: flex-start;
}

.activity-title {
    font-size: 24px;
    color: #32364e;
    font-weight: bold;
    margin-bottom: 8px;
}
.activity-content {
    word-break: break-word;
    color: black;
    font-size: 16px;
}
.activity-content:hover {
    cursor: pointer;
}
::v-deep(.view-more-text) {
    color: #33CC99;
    pointer-events: none;
}
::v-deep(.activity-content a) {
    font-weight: bold;
}
::v-deep(.security-mention), ::v-deep(.tag-mention) {
    color: #33CC99;
}
::v-deep(.analyst-mention) {
    color: #693BF5;
}


[class*='image-preview'] {
    margin-top: 8px;
    border: 1px solid black;
    border-radius: 8px;
    height: 200px;
}
[class*='image-preview'] img {
    width: 100%;
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
}
.image-preview-thumbnail {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
}
.image-preview-thumbnail.wide-photo {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
}

.image-preview-thumbnails {
    width: 100%;
}
.image-preview-thumbnails > img {
    width: 50%;
}
.image-preview-thumbnails img:first-of-type {
    padding-right: 8px;
}

.activity-stats-bar {
    display: flex;
    margin: 16px 0px;
}
.activity-stats-bar > span{
    color: #32364e;
    display: flex;
    align-items: center;
}
.activity-stats-bar > span i {
    margin-right: 4px;
    font-size: 20px;
}
.activity-stats-bar i.own-post:hover {
    cursor: default;
}
.activity-stats-bar > span i:not(.own-post):hover {
    color: #33CC99;
}
.activity-stats-bar .likes-container {
    margin-right: 16px;
}
.activity-stats-bar .likes-container .pi-thumbs-up.liked {
    color: #33CC99;
}

[class*='-comment-block'] {
    border-top: 1px solid rgb(226, 226, 226); 
    padding: 16px 16px; 
}
.recent-comment-block .recent-comment-row {
    display: flex;
}
.recent-comment-block .recent-comment-row .comment-section {
    width: 100%;
    flex: 0 1 auto;
}
.recent-comment-block .recent-comment-row .comment-section .view-more-text {
    color: #a6a6a6;
    padding-top: 2px;
}
.no-comment-block {
    display: flex;
    align-items: center;
}
::v-deep(.no-comment-block .p-inputtext) {
    color: transparent;
    border-radius: 10px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    height: 32px;
    padding: 8px 0px 8px 20px;
    flex: 1;
}
::v-deep(.no-comment-block .p-inputtext:hover) {
    border-color: #BFBFBF;
    cursor: pointer;
}
::v-deep(.no-comment-block .p-inputtext:focus) {
    box-shadow: none;
    border-color: #BFBFBF;
}
::v-deep(.no-comment-block .p-inputtext::placeholder) {
    color: #BFBFBF;
    font-size: 14px;
}

/* .p-avatar {
    border: 1px solid #32364e;
    margin-right: 8px;
}
.p-avatar.p-avatar-xl {
    width: 40px;
    height: 40px;
} */

/*FOLLOW CARD STYLING */

.follow-card {
    display: flex;
    padding: 16px;
    align-items: center;
}
.follow-card:hover {
    cursor: default;
}

.follow-card .followee-container {
    display: flex;
    margin-left: 8px;
    align-items: center;
}

.follow-card .p-avatar:hover {
    cursor: pointer;
}

.follow-card span {
    font-size: 16px;
}

.follow-card .user-handle a {
    font-weight: bold;
    color: #693BF5;
}

@media (max-width: 760px) {
    .nav-image {
        width: 98px;
        height: 25px;
    }

    .sign-up-header {
    padding: 0 10px;
    height: 60px;
    }

    .sign-in-container {
        margin: 0px;
    }

    .activity-card {
    width: 100%;
    border-radius: 0;
    margin-bottom: 8px;
    box-shadow: none;
    }

    .login-link {
    margin-right: 12px;
    font-size: 14px;
    font-weight: 600;
    }   

    .signup-cta, .bottom-signup-cta {
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    background-image: linear-gradient(
        to right,
        #693bf5,
        rgb(105, 59, 245, 0.85),
        rgb(105, 59, 245, 0.7)
    );
    transition: all 0.1s ease-in;
    }

    .signup-cta:hover, .bottom-signup-cta:hover {
        box-shadow: 0 0.4rem 3.2rem rgb(105, 59, 245, 0.6);
        transform: scale(1.05);
        cursor: pointer;
    }

    .signup-cta:active, .bottom-signup-cta:active {
        transform: scale(0.95);
    }

    .bottom-signup-cta {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 100px;
    }

    ::v-deep(.group-profile) {
    margin-top: 74px;
    margin-bottom: 16px;
}
    
}

</style>
