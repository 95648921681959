<template>
    <div :class="commentCardClass">
        
       
            <span class='full-name' >{{stringUtils.fullName(comment.user.data)}}</span> <span class='username' >@{{comment.user.data.name}}</span><span class='time-posted'> | {{timeUtils.getTimeFromNow(commentCreationDate)}}</span>
            <div class='comment-text' v-html="comment.data.content ? comment.data.content : ''"></div>
        
    </div>

    
</template>

<script>



import StringUtils from '../utilities/StringUtils';
import PostUtils from '../utilities/PostUtils';
import TimeUtils from '../utilities/TimeUtils';





export default {
    name: 'GroupPreviewCommentCard',
    props: {
        groupOwnerId: {
            type: Number
        },
        feedPostId: {
            type: String
        },
        comment: {
            type: Object,
            required: true,
        },
        summary: {
            type: Boolean,
            default: false,
        },
        commentLikes: {
            type: Array,
            required: false,
            default: new Array(),
        },
        depthLevel: {
            type: Number,
            default: 1
        }
    },
    emits: ['comment-liked', 'comment-unliked', 'comment-posted', 'cancel-other-pending-comments', 'close-post-dialog'],
    data() {
        return {
            likedClicked: false,
            // comment: this.commentData,
            displayCommentEditor: false,
            childComments: [],
            level: this.depthLevel,
            childCommentsLoading: false,
            offsetLevel: 0,
            showChildComments: true,

            postUtils: null,
            stringUtils: null,
            timeUtils: null
        };
    },

    components: {
        
       
    },

    created() {
        this.postUtils = PostUtils;
        this.stringUtils = StringUtils;
        this.timeUtils = TimeUtils;

       
        
    },

    computed: {
       
        commentCardClass() {
            const levelClassName = `level-${this.level}`;

            return {
                'comment-card': true,
                [levelClassName]: true
            }
        },

        childCommentsStyleObject() {
            return {
                'marginLeft': `${(this.level + 1) * 16}px`
            }
        },

        viewMoreCommentsLabel() {
            if ((this.offsetLevel + 10) < this.comment.children_counts.comment ) {
                return 'View 10 more comments';
            }   
            return `View ${this.comment.children_counts.comment - this.offsetLevel} more comments`;
        },
        entireReplyThreadLabel() {
            return this.showChildComments ? 'Collapse entire reply thread' : 'Show entire reply thread';
        },
        offsetChild() {
            return (this.childComments.length === 0) ? 0 : this.childComments[this.childComments.length - 1].id;
        },

        commentMenuItems() {
            return this.getCommentMenuItems();
        },

        commentCreationDate() {
            return this.comment.created_at ? this.comment.created_at : this.comment.extra.created_at
        }
    },
    mounted() {
        
        
       
    },
    methods: {
        

        

        getExistingLike() {
            let existing = this.commentLikes.filter(e => e.relatedReactionId === this.comment.id);
            if( existing.length > 0 )  {
                return existing[0];
            }
            else {
                return null;
            }
        }, 

        
        
        
    },
};
</script>

<style  scoped>
::v-deep(*:not(.pi)), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
}

/* ::v-deep(figure > img) {
    max-width: 100%;
    min-width: 100%;
} */

.full-name {
    font-weight: bold;
    color: #32364e;
    font-size: 12px;
}
.time-posted ,
.username {
    font-size: 12px;
    color: #a6a6a6;
}
.comment-text {
    color: black;
    font-size: 12px;
    padding-top: 5px;
}

::v-deep(.comment-text > p > img) {
    max-width: 10rem;
}

::v-deep(.comment-text a) {
    font-weight: bold;
}
::v-deep(.comment-text .analyst-mention) {
    color: #693BF5;
}
::v-deep(.comment-text .security-mention) {
    color: #33CC99;
}

::v-deep(a.mention),
.username:hover,
.full-name:hover,
.p-avatar:hover {
    cursor: pointer;
}

/* POST DETAILS DIALOG STYLING */
.comment-card.level-1 {
    margin-bottom: 16px;
}
/* .comment-card.level-2,
.comment-card.level-3{
    margin-bottom: 12px;
} */
.comment-card {
    padding-bottom: 10px;
}

.reply-thread-toggle-text.collapse {
    color: #E63E3E;
}
.reply-thread-toggle-text.show {
    color: #33CC99;
}
.reply-thread-toggle-text:hover {
    text-decoration: underline;
    cursor: pointer;
}

.comment-row {
    display: flex;
    width: 100%;
    
}

/* .comment-card.level-1 > .comment-row {
    margin-bottom:12px;
} */

.comment-row .comment-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom:12px;
}

.comment-row .comment-content .names-bar {
    display: flex;
    width: 100%;
}

.comment-row .comment-content .names-bar .more-options {
    align-self: flex-start;
    margin-left: auto;
    padding: 0px;
}

.comment-row .comment-content .names-bar .full-name {
    margin-right: 8px;
}

.comment-row .comment-content .comment-activity-bar {
    display: flex;
    margin-top: 8px;
    align-items: center;
}
.comment-row .comment-content .comment-activity-bar [class*='container']  {
    display: flex;
    color: #32364e;
    align-items: center;
}
.comment-row .comment-content .comment-activity-bar .likes-container {
    margin-right: 16px;
}
.comment-row .comment-content .comment-activity-bar [class*='container'] i {
    font-size: 16px;
    margin-right: 8px;
}
.comment-row .comment-content .comment-activity-bar [class*='container'] i.liked,
.comment-row .comment-content .comment-activity-bar [class*='container'] i:not(.own-comment):hover {
    cursor: pointer;
    color: #33CC99;
}

.comment-row .comment-content .comment-text {
    margin-top: 4px;
}

.comment-row .comment-content .sub-comment-bar {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

::v-deep(.comment-row .comment-content .sub-comment-bar .comment-editor-bar .comment-editor-container) {
    margin-right: 8px;
}
.comment-card.level-1 ::v-deep(.comment-row) .comment-content .sub-comment-bar .comment-editor-bar .comment-editor-container {
    width: 445px;
}
.comment-card.level-2 ::v-deep(.comment-row) .comment-content .sub-comment-bar .comment-editor-bar .comment-editor-container {
    width: 415px;
}

.comment-card.level-1 ::v-deep(.comment-row) .comment-content .sub-comment-bar .comment-editor-bar div[role='application'] {

    width: 100%;/*445px;*/
}
.comment-card.level-2 ::v-deep(.comment-row) .comment-content .sub-comment-bar .comment-editor-bar div[role='application'] {

    width: 100%;/*415px;*/
}
::v-deep(.comment-row .comment-content .sub-comment-bar .comment-editor-bar div[role='textbox']) {
    min-height: 30px;
}
::v-deep(.comment-row .comment-content .sub-comment-bar .comment-editor-bar .ck.ck-editor__editable_inline) {
    font-size: 14px;
}
::v-deep(.comment-row .comment-content .sub-comment-bar .comment-editor-bar .ck-placeholder) {
    line-height: 1.8;
}

::v-deep(.comment-row .comment-content .sub-comment-bar .comment-editor-bar .ck.ck-dropdown .ck-dropdown__panel[class*=ck-dropdown__panel]) {
    left: -100px;
}

::v-deep(.comment-text img:hover){
    cursor: pointer;
}

/* ::v-deep(.sub-comment-bar .post-comment-button ) {
    font-size: .7rem;
}
::v-deep(.sub-comment-bar  .cancel-comment-button) {
    font-size: .7rem;
} */

/* .like--float {
    opacity: 0;
    margin-left: -10px;
}
.up {
    margin-left: -10px;
    -webkit-animation: slide-out-top-card 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-top-card 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes slide-out-top-card {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    99% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slide-out-top-card {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
} */

@media(max-width: 760px) {
    .sub-comment-bar .p-avatar{
        align-self: flex-start;
        /* margin-right: rem; */
        margin-top: 0.5rem;
    }
}
</style>
