<template>
    <div class='card activity-card-container activity-card' :data-log-id="activityContentDivId" :data-is-note="isNote()" :data-feed-post-id="getFeedPostId()" @click="viewDetails()" >
        <template v-if="activity.verb == 'post' || activity.verb.startsWith('motion') || activity.verb.startsWith('question')">
            
            
                <div class='activity-block' >
                    
                    <div class='status-post-owner-info'>
                        <div class="names-container">
                            <span class='full-name' >{{fullName}}</span>
                            <span class='username'>
                                <span >@{{ activity.actor.data.name }}</span>
                                 | Posted {{timeUtils.getTimeFromNow(activity.time)}}{{ (activity.revision_date ? ' | Edited '+timeUtils.getTimeFromNow(activity.revision_date) : '')}}
                                    {{ activity.group ? '|' : ''}}
                                    <span class="posted-in-span" >
                                        {{ activity.group ? ' Posted in ' + activity.group.name : ''}}
                                        {{ activity.groupFeed ? '- ' + activity.groupFeed.name : ''}}
                                    </span>
                                </span>
                                
                        </div>
                        <div class='actions-container'>
                            
                        </div>
                    </div>
                
                    <div class='activity-title' v-if="activity.title">
                        <template v-if="activity.verb.startsWith('question')">
                        <span class="material-symbols-outlined question-post poll" v-if="activity.poll?.pollTypeId == MULTIPLE_CHOICE_POLL.pollTypeId || activity.poll?.pollTypeId == MULTIPLE_SELECT_POLL.pollTypeId">
                            list_alt
                            </span>
                            <span class="material-symbols-outlined question-post poll" v-else-if="activity.poll?.pollTypeId == YESNO_POLL.pollTypeId">
                            ballot
                            </span>
                            <span class="material-symbols-outlined question-post">
                            emoji_people
                            </span>
                        </template>
                        <span class="material-symbols-outlined question-post" v-else-if="activity.verb.startsWith('motion')">
                        campaign
                        </span>
                        {{ activity.title }}
                    </div>
                    
                    <div :id="activityContentDivId" class='activity-content' v-html="getPostPreviewContent(activity)"  @click="viewDetails()"></div>
                    <a class='view-more-text' v-if='images.length > 0 && textLength < 200 ' @click="viewDetails()">View more</a>


                    <template v-if="activity.poll?.pollTypeId == YESNO_POLL.pollTypeId">
                    <div class="voting-container" :style="(pollClosed ? 'opacity:.3;' : '')">
                        <Button class="yes-vote-button" title="Signup to have your say." @click="openAccountNeededModal($event)">
                            <span class="vote-label">YES</span>
                            <span class="material-symbols-outlined vote-icon">check_circle</span>
                        </Button>
                        <Button class="no-vote-button" title="Signup to have your say."  @click="openAccountNeededModal($event)">
                            <span class="vote-label">NO</span>
                            <span class="material-symbols-outlined vote-icon">cancel</span>
                        </Button>
                    </div>
                    <div class="motion-status-text">
                        
                        <template v-if="pollClosed">
                            <span class='voted'>This poll is now closed.</span>
                        </template>
                    </div>
                </template>
                <template v-else-if="activity.poll?.pollTypeId == MULTIPLE_CHOICE_POLL.pollTypeId">
                    <div class="polling-container" >
                        <div style="opacity:.3;">
                            <div v-for="option in activity.poll.pollOptions" :key="option.pollOptionId" class="poll-option">
                                <label :for="option.pollOptionId" class="ml-2">{{option.code}}.</label>&nbsp;
                                <RadioButton :inputId="option.code+option.pollOptionId" name="pizza" :value="option.pollOptionId" @click="$event.stopPropagation();"/>&nbsp;
                                <label :for="option.pollOptionId" class="ml-2">{{ option.title }}</label>
                            </div>
                            <Button class="yes-vote-button"  title="Signup to have your say." @click="openAccountNeededModal($event)" >
                                <span class="vote-label">Submit</span>
                                <span class="material-symbols-outlined vote-icon">check_circle</span>
                            </Button>
                        </div>
                    </div>
                     <div class="motion-status-text">
                       
                        <template v-if="pollClosed">
                            <span class='voted'>This poll is now closed.</span>
                        </template>
                    </div>
                </template>
                <template v-else-if="activity.poll?.pollTypeId == MULTIPLE_SELECT_POLL.pollTypeId">
                    <div class="polling-container" >
                        <div style="opacity:.3;">
                            <div v-for="option in activity.poll.pollOptions" :key="option.pollOptionId" class="poll-option">
                                <label :for="option.pollOptionId" class="ml-2">{{option.code}}.</label>&nbsp;
                                <Checkbox  :inputId="option.code+option.pollOptionId" name="pizza" :value="option.pollOptionId" @click="$event.stopPropagation();"/>&nbsp;
                                <label :for="option.pollOptionId" class="ml-2">{{ option.title }}</label>
                            </div>
                            <Button class="yes-vote-button" title="Signup to have your say."  @click="openAccountNeededModal($event)">
                                <span class="vote-label">Submit</span>
                                <span class="material-symbols-outlined vote-icon">check_circle</span>
                            </Button>
                        </div>
                    </div>
                     <div class="motion-status-text">
                        
                        <template v-if="pollClosed">
                            <span class='voted'>This poll is now closed.</span>
                        </template>
                    </div>
                </template>
                    
                    <template v-if="images.length > 0 ">
                        <div :class="thumbnailClass(images[0].url)" v-if="images.length == 1">
                            <img :src="images[0].url" />
                        </div>
                        <div class='image-preview-thumbnails' v-else-if="images.length > 1">
                            <template v-for="image in images"  :key="image">
                                <img :src="image.url"/>
                            </template>
                        </div>
                    </template>

                    <div v-if="activity.mentioned_tags" style="margin-top:10px;">
                        <template v-for="feedTag in activity.mentioned_tags" :key="feedTag">
                        <a class="mention tag-mention" data-mention-type="tag"   @click="openAccountNeededModal($event)">{{(feedTag.tag.startsWith("#") ? feedTag.tag : "#"+feedTag.tag)}}</a>
                        &nbsp;
                        </template>

                    </div>
                    <div class='activity-stats-bar'>
                        <span class='likes-container'  @click="openAccountNeededModal($event)">
                            <i class="pi pi-thumbs-up" />
                            <span class='likes-amount' >{{ hasLikeCounts() ? '' + activity.reaction_counts.like : '' }}</span>
                        </span>
                        <span class='comments-container'  @click="openAccountNeededModal($event)">
                            <i class="pi pi-comment" />
                            {{ getCommentCount() }}
                        </span>
                        <template v-if="activity.verb.startsWith('motion')">
                            <div class="motion-status-text" v-html="motionStatusText" v-if="[PASSED_MOTION_STATUS, VETOED_MOTION_STATUS, WITHDRAWN_MOTION_STATUS, FAILED_MOTION_STATUS].includes(activity.motion.motionStatus)"></div>
                            <div class="motion-status-text" v-else>
                            <span class='deadline-passed' v-if="deadlineHasPassed">Deadline has passed, voting is closed.</span>
                            <div class="voting-container" v-else-if="!deadlineHasPassed">
                                <div class='other'>Motion is open for voting.</div> 
                                <Button class="vote-button" label="Vote" />
                            </div>
                           
                        </div>
                        </template>
                        <span class='looks-container' >
                            👀 {{(hasLookCounts() ? activity.reaction_counts.look : '')}}
                        </span>
                        
                    </div>
                </div>
                
                <div class='recent-comment-block' data-role="recent-comment" v-if="!isMobile()">
                    <div class='recent-comment-row'>
                       
                        <div class='comment-section'>
                            <GroupPreviewCommentCard :comment="activity.latest_reactions.comment[0]" :summary="true"  v-if="activity.num_comments > 0"/>
                            
                        </div>
                    </div>
                </div>
               
            
        </template>
         
           
    </div>
   
</template>

<script>


import {PASSED_MOTION_STATUS, FAILED_MOTION_STATUS, WITHDRAWN_MOTION_STATUS, VETOED_MOTION_STATUS,YESNO_POLL, MULTIPLE_CHOICE_POLL, MULTIPLE_SELECT_POLL} from '../common/constants';
import BrowserUtils from '../utilities/BrowserUtils';

import GroupPreviewCommentCard from './GroupPreviewCommentCard.vue';
//import UserAvatar from './UserAvatar';


import TimeUtils from '../utilities/TimeUtils';
import PostUtils from '../utilities/PostUtils';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';



import clip from 'text-clipper';
import Button from 'primevue/button';



export default {
    emits: ['on-view-details', 'on-account-needed'],
    props: {
        selectedFeedType: {
            type: String,
            required: false
        },
        selectedGroupFeed: {
            type: Object,
            required: false
        },
        activity: {
            type: Object,
            required: false
        },
        component: {
            type: String,
            required: false
        },
    },
    data() {
        return {
            WITHDRAWN_MOTION_STATUS, 
            VETOED_MOTION_STATUS,
            PASSED_MOTION_STATUS,
            FAILED_MOTION_STATUS,
            YESNO_POLL, MULTIPLE_CHOICE_POLL, MULTIPLE_SELECT_POLL,

            likedClicked: false,
            images: [],
            template: this.component,
            postUtils: null,
            timeUtils: null,
           
            textLength: 0,
            deletingAsSpam: false,
            postReactions: [],
            reactionCounts: {}
        };
    },
    computed: {
        
        pollClosed() {
            if( this.activity.poll?.closedDate ){
                return true;
            }
            else {
                //return TimeUtils.isSameOrBeforeNow(this.activity.poll?.closedDate);
                return false;
            }
        },
        
        originalPosterFullName() {
            const firstName = (this.activity.object.actor.data.firstName) ? this.activity.object.actor.data.firstName : '';
            const middleName = (this.activity.object.actor.data.middleName) ? this.activity.object.actor.data.middleName : '';
            const lastName = (this.activity.object.actor.data.lastName) ? this.activity.object.actor.data.lastName : '';

            return `${firstName} ${middleName} ${lastName}`
        },
       

        activityContentDivId(){
            let prefix = ''
           return prefix+`activityContent-${this.activity.id}`;
            
        },

        
        fullName() {
            // console.log("fullName", this.activity);
            const firstName = (this.activity.actor.data.firstName) ? this.activity.actor.data.firstName : '';
            const middleName = (this.activity.actor.data.middleName) ? this.activity.actor.data.middleName : '';
            const lastName = (this.activity.actor.data.lastName) ? this.activity.actor.data.lastName : '';

            return `${firstName} ${middleName} ${lastName}`
        },

        deadlineHasPassed() {
            return TimeUtils.isSameOrBeforeNow(this.activity.motion.deadlineDate);
        },

        deadlineDate() {
            return TimeUtils.getFormattedTime(this.activity.motion.deadlineDate, 'YYYY-MM-DD');
        },

         motionStatusText() {
            const MOTION_STATUS_MAP = {
                [PASSED_MOTION_STATUS]: "<span class='passed'>Motion Passed</span> <span class='material-symbols-outlined'>check_circle</span>",
                [WITHDRAWN_MOTION_STATUS]: "<span class='other'>Motion Withdrawn</span>",
                [FAILED_MOTION_STATUS]: "<span class='other'>Motion Failed</span> <span class='material-symbols-outlined'>cancel</span>",
                [VETOED_MOTION_STATUS]: "<span class='other'>Motion Vetoed</span>"
            }

            return MOTION_STATUS_MAP[this.activity.motion.motionStatus];
        },

       
    },

    name: 'ActivityCard',
    components: {
       
       GroupPreviewCommentCard,
        Button,
        //UserAvatar,
        RadioButton,
        Checkbox,
       
    },

    created() {
        this.postUtils = PostUtils;
        this.timeUtils = TimeUtils;
    },

   

    mounted() {
        this.reactionCounts = this.activity.reaction_counts;

        

        let thumbnails = this.activity.thumbnails;
        
        this.images = this.grabActivityThumbnails(thumbnails);
        
    },
    unmounted() {
        // if( this.impressionsDetector) {
        //     console.log("stopping impression detctor");
        //     this.impressionsDetector.stop();

        // }
    },

    methods: {

      
         openAccountNeededModal(evt) {
            evt.stopPropagation();
            evt.preventDefault();
            this.$emit('on-account-needed');
        },
        
       

        viewDetails() {
            this.$emit('on-view-details', this.activity);
        },

        isNote() {
            if( this.activity.verb == 'repost' ) {
                return this.activity.object.has_note ==1;
               
            }
            else {
                 return this.activity.has_note == 1;
            }
        },
       

        isMobile() {
            return BrowserUtils.isMobile();
        },


        getPostPreviewContent(activity) {
           
            return activity.content ? this.pruneContent(activity.content) : this.pruneContent(activity.summary);

        }, 


        pruneContent(content) {
            if( content ) {
                let virtualContentNode = new DOMParser().parseFromString(content, 'text/html');
                this.pruneContentNode(virtualContentNode.body);
                 
                return virtualContentNode.body.innerHTML;
            }
            else {
                return content;
            }
           
        },
        
       

       

        /* Does the removing of images from preview, the clamping of text if too long for previews, and
        preventing the dialog to popup when clicking on normal links */
        pruneContentNode(postContentNode) {
            if (postContentNode) {     
                //console.log("this feed = "+ this.selectedFeedType);         
                
                    this.removeMostMediaFromPreview(postContentNode);
                    this.clampActivityPreview(postContentNode);
            
            }
        },

        // Removes all images and removes all embeds except the first one to allow for video previews on feed.
        removeMostMediaFromPreview(postContent) {
            Array.from(postContent.getElementsByTagName('IMG')).forEach((img) => {
                img.remove();
            })
            
            Array.from(postContent.getElementsByTagName('FIGURE')).forEach((fig) => {
                if( fig.getAttribute("class") == 'image' ) {
                    
                    fig.remove();
                }
            })
            

            let embedCount = 0;
            Array.from(postContent.querySelectorAll('div[data-oembed-url]')).forEach((embed) => {
                if( embedCount > 0 ) { // leave one iframe for media previews
                    embed.remove();
                    
                }
                ++embedCount;
            })


           
        },

       
            

        getReposter() {
            // let groupInfo = ""
            // if( this.activity.object.group ){
            //     groupInfo = " from "+this.activity.object.group.name;
            // }
           return this.fullName;// + groupInfo ;
            

        },
        thumbnailClass(url) {
            let img = document.createElement("IMG");
            img.src = url;

            return {
                'image-preview-thumbnail': true,
                'wide-photo': img.width > img.height
            }
        },
        

        hasComments() {
            if( this.activity.verb == "repost" ) {
                return this.activity.object.latest_reactions && this.activity.object.latest_reactions.comment && this.activity.object.latest_reactions.comment.length > 0;
            }
            else {
                return this.activity.latest_reactions && this.activity.latest_reactions.comment && this.activity.latest_reactions.comment.length > 0;
            }
        },
        hasLikeCounts() {
             if( this.activity.verb == "repost" ) {
                 return this.activity.object.reaction_counts && this.activity.object.reaction_counts.like;
             }
             else {
                return this.activity.reaction_counts && this.activity.reaction_counts.like;
             }
        },

        hasLookCounts() {
            if( this.activity.verb == "repost" ) {
                 return this.activity.object.reaction_counts && this.activity.object.reaction_counts.look;
             }
             else {
                return this.activity.reaction_counts && this.activity.reaction_counts.look;
             }
        },

        getLookCounts() {

        },

        hasCommentCounts() {
            if( this.activity.verb == "repost" ) {
                //console.log("repost: "+this.activity.object.num_comments)
                //return this.activity.object.reaction_counts && this.activity.object.reaction_counts.comment;
                return this.activity.object.num_comments;
            }
            else {
                //console.log("post: "+this.activity.num_comments)
                //return this.activity.reaction_counts && this.activity.reaction_counts.comment;
                return this.activity.num_comments;
            }
        },

        getCommentCount() {
            return this.hasCommentCounts() ? '' +  this.activity.num_comments : '';
        },
         getRepostCommentCount() {
            return this.hasCommentCounts() ? '' + this.activity.object.num_comments : '';
        },

       

       

        
        getFeedPostId() {
            if(this.activity.verb == 'repost') {
                
                return this.activity.object.foreign_id;
            }
            else {
                return this.activity.foreign_id;
            }
        },

       

        
        grabActivityThumbnails(thumbnails) {
            let imageCounter = 0;
            let arr = [];

            if( thumbnails && thumbnails.length > 0 ) {
                thumbnails.forEach(thumb => {
                    if( imageCounter < 2 ){
                        // let img = document.createElement("IMG");
                        // img.src = thumb.url;
                        // img.crossOrigin = "anonymous";

                        // this.resizeImage(img);
                        arr.push(thumb);
                        ++imageCounter;
                    }
                });
            }

            return arr;
        },

        findNearestParent(node) {
            let parent = node.parentNode;
            const tagNames = ['STRONG', 'A', 'I'];

            /* This is to ensure that the view more element is not within the tags from bolding, italizing and of a mention - Just prevents any
            accidental sharing of styling or wierd clicking behaviour (If it's in a mention) */
            while (tagNames.includes(parent.tagName)) {
                parent = parent.parentNode;
            }

            return parent;
        },
        clampActivityPreview(element) {
            this.textLength = element.innerText.length;
            
            if (this.textLength >= (this.isMobile() ? 200: 300)) {
                let parentNode = null;
                const id = this.activity.verb == 'repost' ? `${this.activity.object.id}-viewMoreText` : `${this.activity.id}-viewMoreText`;
                const indicatorString = `<a id="${id}-viewMoreText" class='view-more-text' href='#'>...View more</a>`;

                // 268 includes the character length of the view more link element
                element.innerHTML = clip(element.innerHTML, 268, {breakWords: true, html: true, indicator: indicatorString})

                this.$nextTick(() => {
                    const viewMoreTextEl = document.getElementById(`${id}-viewMoreText`);

                    // For some reason, if the clipping happens to be at the actual end of a string - It won't add the indicator value (The view more element). Most likely due to HTML structure
                    if (!viewMoreTextEl) { // Manually add in the view more element at the end of element/string in the last child (Including nested) of the whole content
                        const newViewMoreTextEl = document.createElement('A');
                        newViewMoreTextEl.innerText = '...View More';
                        newViewMoreTextEl.id = `${id}-viewMoreText`;
                        newViewMoreTextEl.className = 'view-more-text';

                        let lastChild = element.lastChild;

                        while(lastChild) {
                            if (lastChild.lastChild) {
                                lastChild = lastChild.lastChild;
                            } else {
                                break;
                            }
                        }

                        parentNode = this.findNearestParent(lastChild);

                        // We find the most nearest parent that's not one of those types of elements, and append it as the last child (So that the link element is still beside it and inline)
                        parentNode.appendChild(newViewMoreTextEl);

                        // We replace the content within the activity content div with the new content that has the appended view more text
                        const activityContentDiv = document.getElementById(this.activityContentDivId);
                        if(activityContentDiv && element.firstChild){
                            activityContentDiv.firstChild.replaceWith(element.firstChild);
                        }
                    }
                });
            }
        },

        
       
        
        

    }
};
</script>
<style>
.p-component {
    font-family: 'Trebuchet MS', 'Verdana';
}



/* .p-menu {
    padding: 0px;
    border-radius: 8px;
}
.p-menu .p-menuitem-link {
    border-radius: 8px;
}
.p-menu .p-menuitem-link .p-menuitem-text,
.p-menu .p-menuitem-link .p-menuitem-icon {
    color: #32364e;    
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: #32364e;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #FFFFFF;
}
.p-menu .p-menuitem-link:focus {
    box-shadow: none;
} */

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
::v-deep(*:not(.pi, .material-symbols-outlined)), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
}
v::deep(.disabled-menu) {
    pointer-events: none;
}

.question-post {
    color: #32364E; /*#33CC99;*/
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 26px;
}

.poll {
    font-size:18px;
    left: 12px;
    top: -4px;
}

.motion-post {
    color: #32364E; /*#33CC99;*/
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 26px;
}

.poll-option{
    margin-bottom: 5px;
    font-size: 1.5rem;
}

.polling-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
}
.voting-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
}
.voting-container button, .polling-container button {
    display: flex;
    align-items: center;
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 0px 8px;
    background: none;
}
.voting-container button:hover, .polling-container button:hover{
    transform:rotate(0.2deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
    animation-iteration-count: infinite;
    background: none;
    border-color: none;
    box-shadow: none;
}
.voting-container .yes-vote-button, .polling-container .yes-vote-button {
    margin-right: 12px;
}
.voting-container .yes-vote-button:hover, .polling-container .yes-vote-button:hover {
    border-color: #33CC99;
}
.voting-container .no-vote-button:hover {
    border-color: #E63E3E;
}
.voting-container .vote-label, .polling-container .vote-label{
    font-weight: bold;
    color: #32364e;
    font-size: 14px;
}
.voting-container .yes-vote-button .vote-icon, .polling-container .yes-vote-button .vote-icon{
    color: #33CC99;
}
.voting-container .no-vote-button .vote-icon {
    color: #E63E3E;
}
.voting-container .vote-icon , .polling-container .vote-icon{
    font-size: 24px;
    
}

.looks-container{
    margin-left: auto;
    font-size: 12px;
}

.likes-container {
    font-size: 12px;
}
.tag-mention {
    font-size: 12px;
}

::v-deep(.activity-content ul) {
    padding-inline-start: 40px;
}
::v-deep(.activity-content ol) {
    padding-inline-start: 40px;
}

/* STYLING FOR MOTION ACTIVITY CARDS */
.motion-status-text {
    font-size: 11px;
    margin-top:10px;
}
.motion-status-text .passed {
    color: #33CC99;
    font-weight: bold;
}
.motion-status-text .failed {
    color: #E63E3E;
    font-weight: bold;
}
.motion-status-text .deadline-passed,
.motion-status-text .other {
    color: #32364E;
}
.motion-status-text .voted {
    color: #32364E;
}
.motion-status-text .material-symbols-outlined {
    color: #32364E;
    font-size: 16px;
}

.motion-status-text .voting-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.motion-status-text .voting-container .other {
    margin-right: 6px;
}
.motion-status-text .voting-container .vote-button {
    display: flex;
    align-items: center;
    border: 1px solid #32364E;
    border-radius: 50px;
    padding: 4px;
    background: none;
}
.motion-status-text .voting-container .vote-button:hover {
    transform:rotate(0.2deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
    animation-iteration-count: infinite;
    background: none;
}
.motion-status-text .voting-container .vote-button:focus {
    box-shadow: none;
}
.motion-status-text .voting-container .vote-button ::v-deep(.p-button-label) {
    font-weight: bold;
    color: #32364e;
    font-size: 12px;
}

.motion-activity-block .activity-stats-bar {
    align-items: center;
}
/* .motion-activity-block .looks-container {
    margin: 0;
} */
.motion-status-text {
    margin-left: auto;
    margin-right:auto;
}


.enabled-menu {
}

/* GENERAL STYLING FOR ALL ACTIVITY CARDS */

::v-deep(.not-clickable) {
    pointer-events: none;
}

.card {
    padding: 0px;
    border-radius: 16px;
    margin-bottom: 16px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.card:hover {
    cursor: pointer;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
}
.row {
    padding-bottom: 16px;
    border-radius: 0px;
    margin-bottom: 0px;
    box-shadow: none;
    border-bottom: 1px solid #BFBFBF;
}
.row:nth-child(6) {
    border-bottom: none;
}
.row:hover {
    cursor: pointer;
}

.activity-block {
    padding: 16px 16px 0px;
}

.status-post-owner-info {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.status-post-owner-info .names-container {
    display: block;
    padding-right: 15px;
}
.status-post-owner-info .names-container .full-name {
    display: block;
    color: #32364e;
    font-size: 18px;
    font-weight: bold;
}
.status-post-owner-info .names-container .username {
    display: block;
    color: #a6a6a6;
    font-size: 12px;
}
.status-post-owner-info .names-container .posted-in-span {
    color: #33CC99;
}

.actions-container {
    margin-left: auto;
    display: flex;
    align-items: center;
}
.actions-container > * {
    margin-right: 8px;
}
.actions-container > *:last-child {
    margin-right: 0px;
}

::v-deep(.follow-button) {
    height: 25px !important;
    margin-right: 8px;
}

.following-badge {
    font-size: 14px;
    font-weight: normal;
    height: 25px !important;
    display: inline-flex;
    align-items: center;
}

.repost-info {
    display: flex;
    align-items: center;
    padding: 16px 16px 0px;
}
.repost-info .username {
    color: #a6a6a6;
    font-size: 12px;
}

.activity-title {
    font-size: 24px;
    color: #32364e;
    font-weight: bold;
    margin-bottom: 8px;
}
.activity-content {
    word-break: break-word;
    color: black;
    font-size: 16px;
}
.activity-content ::v-deep(.image) {
    margin: 0 auto !important;
}
::v-deep(.activity-content img) {
    max-width: 100%;
}

.activity-content ::v-deep(.image.image-style-side) {
    float: right;
}
.activity-content:hover {
    cursor: pointer;
}
::v-deep(.view-more-text) {
    color: #33CC99;
    pointer-events: none;
}
::v-deep(.activity-content a) {
    font-weight: bold;
}
::v-deep(.activity-content .security-mention) {
    color: #33CC99;
}
::v-deep(.activity-content .analyst-mention) {
    color: #693BF5;
}

[class*='image-preview'] {
    margin-top: 8px;
    border: 1px solid black;
    border-radius: 8px;
    height: 200px;
}
[class*='image-preview'] img {
    width: 100%;
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
}
.image-preview-thumbnail {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
}
.image-preview-thumbnail.wide-photo {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
}

.image-preview-thumbnails {
    width: 100%;
}
.image-preview-thumbnails > img {
    width: 50%;
}
.image-preview-thumbnails img:first-of-type {
    padding-right: 8px;
}

.activity-stats-bar {
    display: flex;
    margin: 16px 0px;
}
.activity-stats-bar > span{
    color: #32364e;
    display: flex;
    align-items: center;
}
.activity-stats-bar > span i {
    margin-right: 4px;
    font-size: 20px;
}
.activity-stats-bar i.own-post:hover {
    cursor: default;
}
.activity-stats-bar > span i:not(.own-post):hover {
    color: #33CC99;
}
.activity-stats-bar .likes-container {
    margin-right: 16px;
}
.activity-stats-bar .likes-container .pi-thumbs-up.liked {
    color: #33CC99;
}
.activity-stats-bar .likes-container .likes-amount:hover {
    color: #33CC99;
}

.activity-stats-bar ::v-deep(.comments-container) {
    margin-right: 16px;
    font-size:12px;
}

.reaction-bar-container {
    display: flex;
    margin-bottom: 8px;
}
.reaction-icon {
    color: #32364e;
    vertical-align: middle;
}
.reaction-icon:hover {
    cursor: pointer;
    transform:rotate(0.4deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
}
.reaction-container {
    margin-right: 4px;
}
.reaction-container:last-child {
    margin-right: 0;
}
.reaction-container.own-post > span:hover {
    cursor: default;
    pointer-events: none;
}
.reaction-container.reacted .num {
    color: #33CC99;
}

[class*='-comment-block'] {
    border-top: 1px solid rgb(226, 226, 226); 
    padding: 16px 16px; 
}
.recent-comment-block .recent-comment-row {
    display: flex;
}
.recent-comment-block .recent-comment-row .comment-section {
    width: 100%;
    flex: 0 1 auto;
}
.recent-comment-block .recent-comment-row .comment-section .view-more-text {
    color: #a6a6a6;
    padding-top: 2px;
}
.no-comment-block {
    display: flex;
    align-items: center;
}
::v-deep(.no-comment-block .p-inputtext) {
    color: transparent;
    border-radius: 10px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    height: 32px;
    padding: 8px 0px 8px 20px;
    flex: 1;
}
::v-deep(.no-comment-block .p-inputtext:hover) {
    border-color: #BFBFBF;
    cursor: pointer;
}
::v-deep(.no-comment-block .p-inputtext:focus) {
    box-shadow: none;
    border-color: #BFBFBF;
}
::v-deep(.no-comment-block .p-inputtext::placeholder) {
    color: #BFBFBF;
    font-size: 14px;
}

.p-avatar {
    margin-right: 8px;
}
/*.p-avatar.p-avatar-xl {
    width: 40px;
    height: 40px;
} */

/*FOLLOW CARD STYLING */

.follow-card {
    display: flex;
    padding: 16px;
    align-items: center;
}
.follow-card:hover {
    cursor: default;
}

.follow-card .followee-container {
    display: flex;
    margin-left: 8px;
    align-items: center;
}

.follow-card .p-avatar:hover {
    cursor: pointer;
}

.follow-card span {
    font-size: 16px;
}

.follow-card .user-handle a {
    font-weight: bold;
    color: #693BF5;
}

/* SPECIFIC STYLING FOR POPULAR NOTES COMPONENT */
.popular-notes-card .activity-title {
    font-size: 16px;
    margin-bottom: 16px;
}

.popular-notes-card .activity-content-row {
    display: flex;
    align-items: center;
}
.popular-notes-card .activity-content-row .activity-content-column {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.popular-notes-card .activity-content-row .image-preview-thumbnail {
    align-self: center;
    flex: 0 1 80px;
    height: 60px;
    margin-top: 0px;
    
}
.popular-notes-card .image-preview-thumbnail img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.popular-notes-card .activity-content {
    font-size: 14px;
}

.popular-notes-card .activity-stats-bar {
    margin-bottom: 0px;
}
.popular-notes-card .activity-stats-bar .full-name {
    font-size: 12px;
    font-weight: bold;
    color: #BFBFBF;
    margin-right: 24px;
}
.popular-notes-card .activity-stats-bar .likes-container {
    margin-right: 16px;
}

.popular-notes-card .activity-stats-bar > span i {
    font-size: 15px;
}
.popular-notes-card .activity-stats-bar .reaction-container {
    display: flex;
    align-items: center;
}
.popular-notes-card .activity-stats-bar .reaction-container .reaction-icon {
    color: #32364e;
    font-size: 20px;
}
.popular-notes-card .activity-stats-bar [class*='container'] [class*='amount'] {
    font-size: 12px;
}
.popular-notes-card .reaction-bar-container {
    margin-top: 8px;
}

.margin-bottom-container {
    margin-bottom: 10vh;
}

@media (max-width: 760px) {
    /* .activity-block {
        padding-left: 15px;
        padding-right: 15px;
    } */
    .card {
        /* padding-left: 10px;
        padding-right: 10px; */
        border-radius: 0;
        margin-bottom: 8px;
        box-shadow: none;
    }
    .card:hover {
        box-shadow: none;
    }  
    
    ::v-deep(.activity-content > figure ){
        margin-inline-start: 0 !important;
    }

    .status-post-owner-info .names-container {
        flex: 1;
    }
    
}
::v-deep(.embedly-card) {
    max-height: 680px;
     transform-origin: top center;
    -webkit-transform:scale(0.8);
    -moz-transform-scale:scale(0.8);
}
::v-deep(.embedly-card-hug) {
    max-height: 400px;
    
}

::v-deep(.insta-iframe) {
    
     transform-origin: top center;
     -webkit-transform:scale(0.5);
    -moz-transform-scale:scale(0.5);
    height:780px;
}

 ::v-deep(.insta-blockquote){
     max-height: 400px;
     
 }
</style>